<template>
  <footer class="footer">
    <div class="footer__inner">
      <p class="copyright" v-html='$t("landing.footer.copyright")'></p>
      <div class="footer__info">
        <router-link :to="{name: 'LandingContact'}" class="footer__tel hover__line"
        >Contacts</router-link
        >
        <router-link :to="{name:'LandingPrivacy'}" class="footer__mail hover__line"
        >Privacy & Legal</router-link
        >
        <div class="footer__socials">
<!--          <a href="#" class="social__link">-->
<!--            <img src="@/assets/landing/img/icons/social_fb.png" alt="" />-->
<!--          </a>-->
          <a href="https://www.instagram.com/guest.eco/" target="_blank" class="social__link">
            <img src="@/assets/landing/img/icons/social_inst.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "LandingFooter"
}
</script>

<style lang="scss">
.footer{
  height: 80px;
}
</style>
